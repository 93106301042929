/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, TwoColCta, TrustLogos, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TrustLogos) _missingMdxReference("TrustLogos", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you or someone you know has hearing loss, the inability to understand speech is most likely one of the biggest pain points. When you’re in noisier environments, such as family get-togethers and restaurants, it can be hard to keep up with the conversation when background noise interferes with the clarity of speech."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The good news is you can improve speech clarity with the right ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ". However, not all hearing aids are made equal. It's important to choose the right ones for your individual needs, as no two ears are alike. Hearing each and every word is possible when you wear devices that are designed for your specific hearing loss and have the right ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "technology"), "."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "best-hearing-aids-for-speech-focus-and-clarity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#best-hearing-aids-for-speech-focus-and-clarity",
    "aria-label": "best hearing aids for speech focus and clarity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Best hearing aids for Speech Focus and Clarity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Recently, Hear.com audiologists partnered with sound engineers from Signia — one of the largest and well known hearing aid manufacturers — to find a solution that would help people overcome speech clarity challenges. Known as Speech Focus, this popular hearing aid feature amplifies  high-pitched frequencies of voice and speech, rather than trying to balance speech with noise like other hearing aids do. It features incredibly narrow sound directionality, allowing you to be laser-focused on speech you need and want to hear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the exclusive Horizon line of hearing aids by hear.com. No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This special speech-enhancing feature is only found in the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX hearing aid line"), ". By activating it in the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/app/",
    className: "c-md-a"
  }, "Horizon app"), " with just one tap, your hearing aids will be 100% focused on speech. No program in any other device has been able to replicate this feature."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-speech-clarity-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-speech-clarity-technology",
    "aria-label": "hearing aids speech clarity technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids Speech Clarity Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to better speech clarity, modern hearing aids have come a long way in the past couple of decades. From watching TV and talking on the phone to more challenging listening environments out in public, major advancements have been made to improve the understanding of speech in all environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here are a handful of speech-enhancing ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "technologies"), " that have been developed specifically to optimize speech clarity."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Digital Signal Processing (DSP) algorithms can automatically detect and separate speech from background noise."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Directional microphones help hearing aid wearers focus on sounds coming from the front, while at the same time reducing sounds from other directions."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise reduction algorithms identify and work to reduce background noise."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech enhancement algorithms amplify specific speech frequencies or features — such as consonant sounds. For example, the Horizon IX hearing aid come equipped with a special Speech Focus mode that boosts speech to an incredibly\nclear level."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Feedback management works to suppress and prevent feedback that can occur from the hearing aid speaker"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Artificial Intelligence (AI) algorithms allow hearing aids to automatically adapt to your environment in real time. With AI, hearing aids can continue to learn and adapt to your listening preferences and habits over time to improve your hearing experience."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " has helped more than 100,000 people across the U.S. stay connected and in the conversation. As one of the most trustworthy ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid companies"), ", our hearing care specialists make your best hearing their priority and provide guidance every step of the way so you never have to go it alone. Our ", React.createElement(_components.a, {
    href: "/audiologists/",
    className: "c-md-a"
  }, "partner audiologists"), " are nearby, too, in almost all 50 states. So you’ll never have to go far for quality hearing care."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, " "), "\n", React.createElement("div", {
    style: {
      display: 'flex',
      justifyContent: 'center'
    }
  }, React.createElement(TrustLogos)), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, " "), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "crystal-clear-speech-hearing-aids-starts-here",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#crystal-clear-speech-hearing-aids-starts-here",
    "aria-label": "crystal clear speech hearing aids starts here permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Crystal-clear speech hearing aids starts here"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It takes just one click to get started and experience the best in speech clarity. There is no risk to try the Horizon Go IX in your everyday life. In fact, you’ll have 45 days to see how well they work for you in every environment. We’re ready to work with you and help you every step of the way."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
